import React from 'react';

const FaqData = [
  {
    question: 'When can I start doing jobs?',
    content: (
      <>
        <br />
        <div>
          You can start earning on GoGet as soon as you complete the online
          training and become a verified GoGetter.
        </div>
      </>
    )
  },
  {
    question: 'Can I choose my working hours?',
    content: (
      <>
        <br />
        <div>
          Yes! As a GoGetter, you get to choose the type of work you like:
        </div>
        <br />
        <div>
          <strong>I. Hourly gig jobs</strong>
        </div>
        <br />
        <div>
          Short task-based jobs that can be completed in less than an hour or up
          to 3 days. You can do these jobs during your free time, weekends or
          even after office hours.
        </div>
        <br />
        <div>
          <strong>II. Contract jobs</strong>
        </div>
        <br />
        <div>
          Contract jobs are jobs that are not full-time and has a specified time
          period e.g. Be a packer GoGetter from 9am to 5pm for 4 months.
        </div>
        <br />
        <div>
          <strong>III. Part-time jobs</strong>
        </div>
        <br />
        <div>
          Part-time jobs are jobs that are not full-time and has {'<40'} hours
          per week. e.g. Be a packer GoGetter to work 3-hours night shift every
          night.
        </div>
        <br />
        <div>
          <strong>IV. Full time jobs</strong>
        </div>
        <br />
        <div>
          Full time jobs are also known as full time employment (working minimum
          40 hours per week). e.g. Be a full-time packer GoGetter hired under
          the company.
        </div>
      </>
    )
  },
  {
    question: 'What are my working hours as a GoGetter?',
    content: (
      <>
        <br />
        <div>
          There are no fixed working hours as a GoGetter. Jobs are available 24
          hours, 7 days a week!
        </div>
        <br />
        <div>
          You are free to work only when you can, by claiming jobs you want to
          do.
        </div>
      </>
    )
  },
  {
    question: 'Does GoGet charge commission?',
    content: (
      <>
        <br />
        <div>There are 2 types of job on the GoGetter app:</div>
        <br />
        <div>
          <strong>I. Hourly gig jobs</strong>
        </div>
        <br />
        <div>
          GoGet charges a 20% commission for each completed hourly gig jobs.
          However, we offer many incentives that will allow you to earn more
          when you do more jobs!
        </div>
        <br />
        <div>
          <strong>II. 'Hire Me' jobs</strong>
        </div>
        <br />
        <div>
          There is no commission charged on 'Hire Me' jobs. You will be able to
          find part-time, contract or full time jobs for free.
        </div>
      </>
    )
  },
  {
    question: 'How much can I earn?',
    content: (
      <>
        <br />
        <div>As much as possible!</div>
        <br />
        <div>
          The more jobs you do, the more you earn. Average earnings for
          GoGetters starts from RM10/ hour and may be higher depending on the
          type of jobs that you do. GoGetters have earned up to RM10,000 in a
          month.
        </div>
      </>
    )
  },
  {
    question: 'How do I get paid?',
    content: (
      <>
        <br />
        <div>There are 3 types of payment methods :</div>
        <br />
        <div>
          <strong>Hourly gig jobs</strong>
        </div>
        <br />
        <div>
          i) Payment from GoGet Admin directly into your bank account (3 times a
          week).
        </div>
        <br />
        <div>
          ii) Paid directly by Poster in cash upon job completion. *Available
          for certain job types only
        </div>
        <br />
        <div>
          <strong>'Hire Me' jobs</strong>
        </div>
        <br />
        <div>
          iii) You will get paid directly by the job Poster according to their
          payment cycle. This may range from daily, weekly, or monthly.
        </div>
      </>
    )
  },
  {
    question: 'Is there any vehicle requirement?',
    content: (
      <>
        <br />
        <div>
          No, there are no vehicle requirements to be a GoGetter. There are many
          types of jobs available where no transportation is required.
        </div>
        <br />
        <div>
          If the job requires personal transportation, you are free to use your
          own vehicle as long as it is in a good working condition.
        </div>
      </>
    )
  },
  // pass empty object to fix the styling
  {
    question: '',
    content: <></>
  }
];

export { FaqData };
